export const Tooltips = {
  WorkPermit: `Candidates do not require a work permit if:
- They have an EU passport or ID, or
- They hold a partner visa, or
- They currently hold a Search Year permit in The Netherlands after their study`,
  ThirtyPercentRuling: `Candidates who currently have the 30% ruling or meet the
following conditions may be eligible for the 30% ruling:
The candidate:
- Is currently residing outside the Netherlands
- Has lived more than 150 KM in a straight line from the Dutch border for at
  least 16/24 months
- Meets the minimum salary threshold`,
  LGNoSOW: `Magno receives a maximum fee of 12.5% of the All-in candidate rate.
Please make sure this fee meets the minimum margin requirements.

Candidate receives max available rate based on the max sell rate.
Candidate pays broker fee of € 6.80 per day.
Candidate pays mark-up fee of € 2.00 per day.`,
  Telenet: `Magno receives a maximum fee of 12.5% of the All-in candidate rate.
Please make sure this fee meets the minimum margin requirements.
  
Candidate receives max available rate based on the max sell rate.`,
}
