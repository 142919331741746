import React from 'react'
import { CommitmentMail } from './CommitmentMail'

export const Employed = () => {
  return (
    <div>
      <div className="dashboard-form proforma-left">
        <CommitmentMail type="Employed" />
      </div>
    </div>
  )
}
