import React, { useState } from 'react'
import qs from 'qs'
import { getApiUrl, getSessionId } from '../lib/http'

import './CommitmentMail.css'

import {
  CommitmentMailLanguages,
  CommitmentMailType,
  CommitmentParametersNonPayroll,
  NonPayrollCommitmentForm,
  YesNo,
  Client,
} from '../types/types'
import { Input } from './Input'
import { useClients } from '../hooks/useClients'
import { TextNumberInput } from './TextNumberInput'
import { validate } from '../validations/nonPayrolled'
import { NonPayrollRateEntry } from './NonPayrollRateEntry'
import { SalaryEntry } from './SalaryEntry'
import { INITIAL_NON_PAYROLL_FORM } from './values'
import { MigrantEntry } from './MigrantEntry'

const STATEMENT_OF_WORK_CLIENTS = ['Liberty Global', 'VodafoneZiggo']

const getDownloadUrl = (parameters: CommitmentParametersNonPayroll) => {
  const sessionId = getSessionId()
  const commitmentQueryString = qs.stringify({
    ...parameters,
    token: sessionId,
  })
  return getApiUrl() + `/commitment?${commitmentQueryString}`
}

const mapToCommitmentParams = (
  form: NonPayrollCommitmentForm
): CommitmentParametersNonPayroll => {
  const statementOfWorkMayApply =
    form.type === 'SelfEmployed' &&
    STATEMENT_OF_WORK_CLIENTS.includes(form.client)
  return {
    Type: form.type,
    ClientName: form.client,
    Language: form.language,
    AllInRate: form.allInRate,
    AllInRateUnit: form.allInRateUnit,
    WorkHoursPerWeek: form.workTimePerWeek,
    StatementOfWork: statementOfWorkMayApply && form.statementOfWork === 'Yes',
    RequiresWorkPermit: form.requiresWorkPermit === 'Yes',
    ThirtyPercentRuling: form.eligible30percentRuling === 'Yes',
  }
}

interface CommitmentMailProps {
  type: CommitmentMailType
}

export const CommitmentMail = ({ type }: CommitmentMailProps) => {
  const [form, setForm] = useState<NonPayrollCommitmentForm>({
    ...INITIAL_NON_PAYROLL_FORM,
    type: type,
  })
  const [errors, setErrors] = useState([] as string[])

  const onClick = (e: React.SyntheticEvent) => {
    const { errors } = validate(form)
    if (errors.length > 0) {
      setErrors(errors)
      e.preventDefault()
    } else {
      setErrors([])
    }
  }

  const { clients } = useClients()

  const statementOfWorkMayApply =
    type === 'SelfEmployed' && STATEMENT_OF_WORK_CLIENTS.includes(form.client)

  return (
    <div>
      <div className="popup-section-title">Commitment Mail</div>
      {errors.length > 0 && (
        <div className="error">
          {errors.map((error) => (
            <div key={error}>{error}</div>
          ))}
        </div>
      )}
      <div className="form-block">
        <Input label="Client">
          <select
            value={form.client}
            onChange={(e) =>
              setForm({
                ...form,
                client: e.target.value as string,
              })
            }
          >
            {clients
              .sort((a: Client, b: Client) => {
                return a.Name.localeCompare(b.Name)
              })
              .map((client) => (
                <option key={client.ID} value={client.Name}>
                  {client.Name}
                </option>
              ))}
          </select>
        </Input>
      </div>

      {statementOfWorkMayApply && (
        <div className="form-block">
          <Input label="Statement of Work">
            <select
              value={form.statementOfWork}
              onChange={(e) =>
                setForm({
                  ...form,
                  statementOfWork: e.target.value as YesNo,
                })
              }
            >
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </select>
          </Input>
        </div>
      )}

      {type === 'Permanent' ? (
        <SalaryEntry form={form} setForm={setForm} />
      ) : (
        <NonPayrollRateEntry form={form} setForm={setForm} />
      )}

      <div className="form-block">
        <Input label="Work time per week">
          <TextNumberInput
            value={form.workTimePerWeek}
            decimals={0}
            onChange={(value: string) =>
              setForm({ ...form, workTimePerWeek: value })
            }
          />
        </Input>
      </div>

      {type === 'Permanent' && (
        <MigrantEntry form={form} setForm={setForm as any} />
      )}

      <div className="form-block">
        <Input label="Language">
          <select
            value={form.language}
            onChange={(e) =>
              setForm({
                ...form,
                language: e.target.value as CommitmentMailLanguages,
              })
            }
          >
            <option value="EN">English</option>
            <option value="NL">Dutch</option>
          </select>
        </Input>
      </div>

      <div className="form-line">
        <div className="commitment-container">
          <a
            onClick={(e) => onClick(e)}
            href={getDownloadUrl(mapToCommitmentParams(form))}
            className="button-primary button-medium"
          >
            Create commitment mail
          </a>
        </div>
      </div>
    </div>
  )
}
