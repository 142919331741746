import React, { useEffect, useState } from 'react'
import qs from 'qs'

import { TextNumberInput } from './TextNumberInput'
import { authedPost } from '../lib/http'
import {
  YesNoNA,
  CandidateProforma,
  CostType,
  PayrollForm,
  RequestProformaResult,
  WorkTimePerWeek,
  YesNo,
  Client,
} from '../types/types'
import { formToProformaRequest } from './mapper'
import {
  CANDIDATE_LESS_THAN_30_OPTIONS,
  INITIAL_FORM,
  PROFORMA_TYPE_OPTIONS,
  WORK_TIME_PER_WEEK_OPTIONS,
  YES_NO_OPTIONS,
} from './values'
import {
  preValidate,
  postValidate,
  ValidationResult,
} from '../validations/payrolled'
import { CandidateProformaResult } from './CandidateProformaResult'
import { CommitmentMailWithProforma } from './CommitmentMailWithProforma'
import { useClients } from '../hooks/useClients'
import { Input } from './Input'
import { MigrantEntry } from './MigrantEntry'

import './Payrolled.css'
import { WarningIcon } from './WarningIcon'

export const Payrolled = () => {
  const [warnings, setWarnings] = useState([] as string[])
  const [errors, setErrors] = useState([] as string[])
  const [isProcessing, setIsProcessing] = useState(false)
  const [form, setForm] = useState(INITIAL_FORM)
  const [candidateProforma, setCandidateProforma] = useState(
    undefined as CandidateProforma | undefined
  )
  const [inputsLocked, setInputsLocked] = useState(false)
  const [validationResult, setValidationResult] = useState(
    undefined as ValidationResult | undefined
  )

  const { clients } = useClients()

  const setFormFromQueryString = () => {
    const query = window.location.search
    if (query.length > 1) {
      const params = qs.parse(window.location.search.slice(1))
      setForm(params as unknown as PayrollForm)
    }
  }

  useEffect(() => {
    setFormFromQueryString()
  }, [])

  const validate = (form: PayrollForm, grossWagePerHour: number | null) => {
    const validationResult = postValidate({
      ...form,
      proformaType: 'GrossWagePerHour',
      proformaBasis: String(grossWagePerHour || 0),
    })
    setValidationResult(validationResult)
  }

  const findProformas = async (form: PayrollForm) => {
    setIsProcessing(true)
    setCandidateProforma(undefined)
    try {
      const body = formToProformaRequest(form)
      const result = await authedPost(`proformas`, body)
      const json = (await result.json()) as RequestProformaResult
      if (json.data.candidateProforma) {
        const grossWagePerHour = json.data.grossWagePerHour || null
        setCandidateProforma({
          ...json.data.candidateProforma,
          GrossWagePerHour: grossWagePerHour,
          CostPricePerHour: json.data.costPricePerHour || null,
        })
        validate(form, grossWagePerHour)
        setInputsLocked(false)
        setIsProcessing(false)
      } else {
        // if any were processing, we need to wait then request again
        setTimeout(() => {
          findProformas(form)
        }, 5_000)
      }
    } catch (e) {
      console.error(e)
      setIsProcessing(false)
      return setErrors([`Server error: ${(e as any).message}`])
    }
  }

  const getProformas = () => {
    const { errors, warnings } = preValidate(form, clients)
    if (errors.length > 0) {
      setWarnings([])
      setErrors(errors)
    } else {
      setWarnings(warnings)
      setErrors([])
      setInputsLocked(true)
      findProformas(form)
    }
  }

  const displayAgeOptions =
    form.requiresWorkPermit !== 'No' || form.eligible30percentRuling !== 'No'
  const displayHasMastersDegree =
    form.eligible30percentRuling !== 'No' && form.candidateLessThan30 === 'Yes'
  const isDisabled = inputsLocked
  const anyWarnings = validationResult || warnings.length > 0
  const showProformas = validationResult ? validationResult.show : true
  const displayRightPanel = anyWarnings || (candidateProforma && showProformas)

  return (
    <div>
      <div className="dashboard-form proforma-left">
        <div className="popup-section-title">Proforma details</div>
        {errors.length > 0 && (
          <div className="error">
            {errors.map((error) => (
              <div key={error}>{error}</div>
            ))}
          </div>
        )}
        <div className="form-block">
          <Input label="Client">
            <select
              disabled={isDisabled}
              value={form.client}
              onChange={(e) =>
                setForm({
                  ...form,
                  client: e.target.value as string,
                })
              }
            >
              {clients
                .sort((a: Client, b: Client) => {
                  return a.Name.localeCompare(b.Name)
                })
                .map((client) => (
                  <option key={client.ID} value={client.Name}>
                    {client.Name}
                  </option>
                ))}
            </select>
          </Input>
        </div>
        <div className="form-block">
          <Input label="Proforma based on">
            <select
              disabled={isDisabled}
              value={form.proformaType}
              onChange={(e) =>
                setForm({
                  ...form,
                  proformaType: e.target.value as CostType,
                })
              }
            >
              {PROFORMA_TYPE_OPTIONS.map((opt) => (
                <option key={opt.value} value={opt.value}>
                  {opt.name}
                </option>
              ))}
            </select>
            <TextNumberInput
              className="number-input-ml10"
              readonly={isDisabled}
              value={form.proformaBasis}
              prefix="&euro;"
              decimals={2}
              onChange={(value: string) =>
                setForm({ ...form, proformaBasis: value })
              }
            />
          </Input>
        </div>
        <MigrantEntry
          form={form}
          setForm={setForm as any}
          isDisabled={isDisabled}
        />
        <div className="form-block">
          <Input label="Work time (per week)">
            <select
              disabled={isDisabled}
              value={form.workTimePerWeek}
              onChange={(e) =>
                setForm({
                  ...form,
                  workTimePerWeek: e.target.value as WorkTimePerWeek,
                })
              }
            >
              {WORK_TIME_PER_WEEK_OPTIONS.map((opt) => (
                <option key={opt} value={opt}>
                  {opt}
                </option>
              ))}
            </select>
          </Input>
        </div>
        {displayAgeOptions && (
          <div className="form-block">
            <Input label="Is candidate less than 30 years old?">
              <select
                disabled={isDisabled}
                value={form.candidateLessThan30}
                onChange={(e) =>
                  setForm({
                    ...form,
                    candidateLessThan30: e.target.value as YesNoNA,
                  })
                }
              >
                {CANDIDATE_LESS_THAN_30_OPTIONS.map((opt) => (
                  <option key={opt.value} value={opt.value}>
                    {opt.name}
                  </option>
                ))}
              </select>
            </Input>
          </div>
        )}
        {displayHasMastersDegree && (
          <div className="form-block">
            <Input label="Does candidate have a Masters degree?">
              <select
                disabled={isDisabled}
                value={form.candidateHasMasterDegree}
                onChange={(e) =>
                  setForm({
                    ...form,
                    candidateHasMasterDegree: e.target.value as YesNo,
                  })
                }
              >
                {YES_NO_OPTIONS.map((opt) => (
                  <option key={opt} value={opt}>
                    {opt}
                  </option>
                ))}
              </select>
            </Input>
          </div>
        )}
        <div className="form-block">
          <Input label="Work-home distance in KM (NL based only)">
            <input
              type="number"
              disabled={isDisabled}
              value={form.workHomeDistance}
              onChange={(e) =>
                setForm({
                  ...form,
                  workHomeDistance: e.target.value,
                })
              }
            />
          </Input>
        </div>
        <div className="form-line">
          <div>
            <button
              className="button-primary button-medium"
              disabled={isDisabled}
              onClick={getProformas}
            >
              Get proformas
            </button>
          </div>
          <div>
            <button
              className="button-primary button-medium"
              disabled={!isDisabled}
              onClick={() => {
                setInputsLocked(false)
                setIsProcessing(false)
                setCandidateProforma(undefined)
              }}
            >
              Change values
            </button>
          </div>
        </div>
      </div>
      {displayRightPanel && (
        <div className="dashboard-form proforma-right">
          {anyWarnings && (
            <div>
              <div className="popup-section-title">Warnings</div>
              {validationResult && (
                <div
                  className={`${validationResult.show ? 'warning' : 'error'}`}
                >
                  <WarningIcon />
                  {validationResult.message}
                </div>
              )}
              {warnings.map((warning, idx) => (
                <div className="warning" key={idx}>
                  <WarningIcon />
                  {warning}
                </div>
              ))}
            </div>
          )}
          {candidateProforma && showProformas && (
            <div>
              <div className="popup-section-title">Results</div>
              <CandidateProformaResult candidateProforma={candidateProforma} />
            </div>
          )}
        </div>
      )}
      {isProcessing && (
        <div className="processing">
          <h1>Processing, please wait. This could take several minutes...</h1>
        </div>
      )}
      {candidateProforma && (
        <CommitmentMailWithProforma
          form={form}
          candidateProforma={candidateProforma}
        />
      )}
    </div>
  )
}
