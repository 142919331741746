import React from 'react'
import { CommitmentMail } from './CommitmentMail'

export const SelfEmployed = () => {
  return (
    <div className="dashboard-form proforma-left">
      <CommitmentMail type="SelfEmployed" />
    </div>
  )
}
