import React from 'react'
import { NonPayrollCommitmentForm } from '../types/types'
import { Input } from './Input'
import { TextNumberInput } from './TextNumberInput'

interface Props {
  form: NonPayrollCommitmentForm
  setForm: (form: NonPayrollCommitmentForm) => void
}

export const SalaryEntry = ({ form, setForm }: Props) => {
  return (
    <div className="form-block">
      <Input label="Salary">
        <TextNumberInput
          className="number-input-mr10"
          value={form.allInRate}
          decimals={2}
          prefix="&euro;"
          onChange={(value: string) => setForm({ ...form, allInRate: value })}
        />
        <span className="label">per year</span>
      </Input>
    </div>
  )
}
