import React, { useState } from 'react'
import qs from 'qs'

import { getApiUrl, getSessionId } from '../lib/http'
import {
  CandidateProforma,
  CommitmentMailLanguages,
  CommitmentParameters,
  PayrollForm,
} from '../types/types'

import './CommitmentMail.css'

const getDownloadUrl = (parameters: CommitmentParameters) => {
  const sessionId = getSessionId()
  const commitmentQueryString = qs.stringify({
    ...parameters,
    token: sessionId,
  })
  return getApiUrl() + `/commitment?${commitmentQueryString}`
}

const mapToCommitmentParams = (
  form: PayrollForm,
  mailSettings: MailSettings,
  candidateProforma: CandidateProforma
): CommitmentParameters => {
  return {
    Type: 'Payrolled',
    ClientName: form.client,
    Detavast: mailSettings.detavast ? 'true' : 'false',
    Months: mailSettings.months,
    Language: mailSettings.language,
    RequiresWorkPermit: form.requiresWorkPermit === 'Yes' ? true : false,
    CostPrice: '' + candidateProforma.CostPricePerHour || '0',
    GrossWage: '' + candidateProforma.GrossWagePerHour || '0',
    WorkHoursPerWeek: '' + candidateProforma.HoursPerWeek,
    NetSalaries: candidateProforma.NetSalaries!,
    DocumentUrls: candidateProforma.DocumentUrls!,
    ThirtyPercentRuling: form.eligible30percentRuling === 'Yes' ? true : false,
  }
}

interface CommitmentMailProps {
  form: PayrollForm
  candidateProforma: CandidateProforma
}

interface MailSettings {
  language: CommitmentMailLanguages
  detavast: boolean
  months: string
}

export const CommitmentMailWithProforma = ({
  form,
  candidateProforma,
}: CommitmentMailProps) => {
  const [mailSettings, setMailSettings] = useState<MailSettings>({
    language: 'EN',
    detavast: false,
    months: '9',
  })

  return (
    <div className="dashboard-form proforma-left">
      <div className="popup-section-title">Commitment Mail</div>

      <div className="form-block">
        <div>
          <div className="label">Language</div>
          <div className="value">
            <select
              value={mailSettings.language}
              onChange={(e) =>
                setMailSettings({
                  ...mailSettings,
                  language: e.target.value as CommitmentMailLanguages,
                })
              }
            >
              <option value="EN">English</option>
              <option value="NL">Dutch</option>
            </select>
          </div>
        </div>
      </div>

      <div className="form-inline">
        <div>
          <div className="label">Detavast</div>
          <div className="value">
            <select
              value={mailSettings.detavast ? 'true' : 'false'}
              onChange={(e) =>
                setMailSettings({
                  ...mailSettings,
                  detavast: e.target.value === 'true',
                })
              }
            >
              <option value="true">Yes</option>
              <option value="false">No</option>
            </select>
          </div>
        </div>
        {mailSettings.detavast && (
          <React.Fragment>
            <div>
              <div className="value">
                <select
                  value={mailSettings.months}
                  onChange={(e) =>
                    setMailSettings({
                      ...mailSettings,
                      months: e.target.value,
                    })
                  }
                >
                  <option value="6">6</option>
                  <option value="9">9</option>
                  <option value="12">12</option>
                </select>
              </div>
            </div>
            <div className="label">months</div>
          </React.Fragment>
        )}
      </div>

      <div className="form-line">
        <div className="commitment-container">
          <a
            href={getDownloadUrl(
              mapToCommitmentParams(form, mailSettings, candidateProforma)
            )}
            className="button-primary button-medium"
          >
            Create commitment mail
          </a>
        </div>
      </div>
    </div>
  )
}
