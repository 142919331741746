import { PayrollForm, ProformaRequest, WorkTimePerWeek } from '../types/types'

export const toNlCurrency = (n: number) => {
  return `€ ${n.toFixed(2).replace('.', ',')}`
}

const mapCostTypeToTaskType = (type: string) => {
  switch (type) {
    case 'CostPricePerHour':
      return 'cp2gw'
    case 'NetSalaryPerMonth':
      return 'net2gw'
    case 'GrossWagePerHour':
      return 'gw2cp'
    default:
      throw new Error('Cannot map proforma cost type: ' + type)
  }
}

const mapTaskTypeToCostType = (type: string) => {
  switch (type) {
    case 'cp2gw':
      return 'CostPricePerHour'
    case 'net2gw':
      return 'NetSalaryPerMonth'
    case 'gw2cp':
      return 'GrossWagePerHour'
    default:
      throw new Error('Cannot map proforma task type: ' + type)
  }
}

const getProformaBasisForType = (request: ProformaRequest) => {
  switch (request.Type) {
    case 'cp2gw':
      return request.CostPricePerHour
    case 'net2gw':
      return request.NetSalaryPerMonth
    case 'gw2cp':
      return request.GrossWagePerHour
    default:
      throw new Error('Cannot map proforma task type: ' + request.Type)
  }
}

export const formToProformaRequest = (form: PayrollForm) => {
  const withoutCommas = form.proformaBasis.replaceAll(',', '')
  const netSalary =
    form.proformaType === 'NetSalaryPerMonth'
      ? Number(withoutCommas)
      : undefined
  const costPrice =
    form.proformaType === 'CostPricePerHour' ? Number(withoutCommas) : undefined
  const grossWage =
    form.proformaType === 'GrossWagePerHour' ? Number(withoutCommas) : undefined
  return {
    Client: form.client,
    Type: mapCostTypeToTaskType(form.proformaType),
    WorkPermit: form.requiresWorkPermit === 'Yes' ? 1 : 0,
    Ruling30: form.eligible30percentRuling === 'Yes' ? 1 : 0,
    HoursPerWeek: Number(form.workTimePerWeek),
    TravelDistanceKm: Number(form.workHomeDistance),
    NetSalaryPerMonth: netSalary,
    CostPricePerHour: costPrice,
    GrossWagePerHour: grossWage,
  } as ProformaRequest
}

export const proformaRequestToForm = (
  request: ProformaRequest
): PayrollForm => {
  return {
    client: request.Client,
    proformaType: mapTaskTypeToCostType(request.Type),
    requiresWorkPermit: request.WorkPermit ? 'Yes' : 'No',
    eligible30percentRuling: request.Ruling30 ? 'Yes' : 'No',
    workTimePerWeek: String(request.HoursPerWeek) as WorkTimePerWeek,
    workHomeDistance: String(request.TravelDistanceKm),
    proformaBasis: String(getProformaBasisForType(request)),
    candidateHasMasterDegree: 'No',
    candidateLessThan30: 'No',
  }
}
