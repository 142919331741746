import React from 'react'

interface Props {
  value: string
  decimals: number
  prefix?: string
  readonly?: boolean
  className?: string
  onChange: (e: string) => void
}

export const TextNumberInput = ({
  value,
  decimals,
  prefix,
  onChange,
  readonly,
  className,
}: Props) => {
  const _onChangeText = (e: any) => {
    let val = e.target.value as string
    if (val && !/[0-9,.]/.exec(val.slice(-1))) {
      return
    }
    const dotPos = val.indexOf('.')
    const commaPos = val.indexOf(',')
    const decimalPos = dotPos !== -1 ? dotPos : commaPos
    if (decimalPos !== -1) {
      if (decimals === 0) {
        val = val.slice(0, decimalPos)
      } else {
        const decimalPlaces = val.slice(
          decimalPos + 1,
          decimalPos + 1 + decimals
        )
        if (decimalPlaces) {
          val = val.slice(0, decimalPos) + '.' + decimalPlaces
        }
      }
    }
    onChange(val)
  }

  return (
    <div className={`number-input ${className}`}>
      {prefix && <span>{prefix}</span>}
      <input value={value} onChange={_onChangeText} disabled={readonly} />
    </div>
  )
}
