import { NonPayrollCommitmentForm } from '../types/types'

export const validate = (form: NonPayrollCommitmentForm) => {
  const errors = []
  const workTimePerWeek = Number(form.workTimePerWeek)
  if (workTimePerWeek < 1 || workTimePerWeek > 40) {
    errors.push('Work time per week must be between 1 and 40 hours')
  }
  return {
    errors
  }
}
