import { Client, PayrollForm } from '../types/types'
import { toNlCurrency } from '../components/mapper'

const GrossWageMininums = {
  '32': {
    Limit1: 32.07,
    Limit2: 43.73,
    Limit3: 41.25,
    Limit4: 31.36,
    Limit5: 41.25,
  },
  '36': {
    Limit1: 28.51,
    Limit2: 38.88,
    Limit3: 36.67,
    Limit4: 27.87,
    Limit5: 36.67,
  },
  '37': {
    Limit1: 27.74,
    Limit2: 37.83,
    Limit3: 35.68,
    Limit4: 27.12,
    Limit5: 35.68,
  },
  '40': {
    Limit1: 25.66,
    Limit2: 34.99,
    Limit3: 33.0,
    Limit4: 25.09,
    Limit5: 33.0,
  },
}

interface HourMap {
  NHM: number
  NHW: number
  MHM: number
  MHW: number
}

type HoursDictionary = {
  [index: string]: HourMap
}

const workingHours: HoursDictionary = {
  '40': {
    NHM: 153.33,
    NHW: 35.38,
    MHM: 173.33,
    MHW: 40,
  },
  '37': {
    NHM: 141.83,
    NHW: 32.73,
    MHM: 160.33,
    MHW: 37,
  },
  '36': {
    NHM: 138,
    NHW: 31.85,
    MHM: 156,
    MHW: 36,
  },
  '32': {
    NHM: 122.67,
    NHW: 28.31,
    MHM: 138.67,
    MHW: 32,
  },
}

export interface ValidationResult {
  message: string
  show: boolean
}

const getWorkingHours = (workTimePerWeek: string): HourMap => {
  return workingHours[workTimePerWeek]
}

const calculateTravelExpenses = (
  formula: string,
  hoursPerWeek: number,
  travelDistanceKm: number,
  nominalHoursPerMonth: number
) => {
  const expression = eval(`(HRS, KM, NHM) => ${formula}`)
  return expression(hoursPerWeek, travelDistanceKm, nominalHoursPerMonth)
}

export const preValidate = (form: PayrollForm, clients: Client[]) => {
  const warnings = []
  const errors = []
  if (!form.proformaBasis) {
    errors.push('Please enter a value for the proforma cost value.')
  }
  if (!form.workHomeDistance || form.workHomeDistance.indexOf('.') !== -1) {
    errors.push('Work-home distance must be a whole number.')
  }
  switch (form.proformaType) {
    case 'CostPricePerHour':
      if (Number(form.proformaBasis) > 200) {
        errors.push(`Please contact the Backoffice to discuss your request.`)
      }
      break
    case 'GrossWagePerHour':
      if (Number(form.proformaBasis) > 100) {
        errors.push(`Please contact the Backoffice to discuss your request.`)
      }
      break
    case 'NetSalaryPerMonth':
      if (Number(form.proformaBasis) > 10_000) {
        errors.push(`Please contact the Backoffice to discuss your request.`)
      }
      break
  }
  const client = clients.find((client) => client.Name === form.client)
  if (!client) {
    console.error(`client not found: ${form.client} clients:`, clients)
    throw new Error('Client not found')
  }
  if (
    client.DefaultWorkingHours &&
    Number(form.workTimePerWeek) !== client.DefaultWorkingHours
  ) {
    warnings.push(
      `The default working hours for this Client are ${client.DefaultWorkingHours} hrs/week.`
    )
  }
  const hours = getWorkingHours(form.workTimePerWeek)
  const NHM = hours.NHM
  if (client.TravelExpensesFormula !== '0') {
    if (client.MaxTravelDistance !== null) {
      const max = client.AlwaysShowMaxExpensesPerMonth
        ? 0
        : client.MaxTravelDistance
      if (Number(form.workHomeDistance) > max) {
        const maxExpenses = calculateTravelExpenses(
          client.TravelExpensesFormula,
          Number(form.workTimePerWeek),
          client.MaxTravelDistance,
          NHM
        )
        warnings.push(
          `Candidate will receive ${toNlCurrency(
            maxExpenses * NHM
          )} per month in travel expenses, which may not cover their full commute.`
        )
      }
    }
    if (
      client.MaxTravelDistance !== null &&
      Number(form.workHomeDistance) === 0
    ) {
      const maxExpenses = calculateTravelExpenses(
        client.TravelExpensesFormula,
        Number(form.workTimePerWeek),
        client.MaxTravelDistance,
        NHM
      )
      warnings.push(
        `Your cost price may increase with max ${toNlCurrency(
          maxExpenses
        )} per hour in case travel expenses apply.`
      )
    }
    if (
      client.MaxTravelDistance === null &&
      Number(form.workHomeDistance) > 0
    ) {
      const maxExpenses = calculateTravelExpenses(
        client.TravelExpensesFormula,
        Number(form.workTimePerWeek),
        Number(form.workHomeDistance),
        NHM
      )
      warnings.push(
        `Travel expenses for this client are unlimited or unknown, and currently take up ${toNlCurrency(
          maxExpenses
        )} per hour of your cost price.`
      )
    }
    if (
      client.MaxTravelDistance === null &&
      Number(form.workHomeDistance) === 0
    ) {
      warnings.push(
        `Travel expenses for this client are unlimited or unknown, and may cause a relevant increase of your cost price.`
      )
    }
  } else {
    warnings.push(`Travel expenses are not compensated for this client.`)
  }
  return { errors, warnings }
}

export const postValidate = (f: PayrollForm): ValidationResult | undefined => {
  const grossWagePerHour = Number(f.proformaBasis)
  const hoursPerWeek = f.workTimePerWeek
  const limits = GrossWageMininums[f.workTimePerWeek]

  if (f.requiresWorkPermit === 'Yes') {
    if (grossWagePerHour < limits.Limit1) {
      return {
        message: `This gross wage does not suffice. The min. gross wage is ${toNlCurrency(
          limits.Limit1
        )} for Knowledge migrants
          under 30 OR ${toNlCurrency(
            limits.Limit2
          )} for Knowledge migrants 30 and older (based on ${hoursPerWeek} hrs/wk)`,
        show: false,
      }
    } else if (grossWagePerHour < limits.Limit2) {
      if (f.eligible30percentRuling === 'Yes') {
        if (grossWagePerHour < limits.Limit3) {
          return {
            message: `The min. gross wage for Knowledge migrants 30 years or older is ${toNlCurrency(
              limits.Limit2
            )}
              (based on ${hoursPerWeek} hrs/wk). Only candidates under 30 with a Master are eligible for the 30% ruling, for
              all others the min. gross wage is ${toNlCurrency(
                limits.Limit3
              )} (based on ${hoursPerWeek} hrs/wk).`,
            show: true,
          }
        } else {
          return {
            message: `The min. gross wage for Knowledge migrants 30 years or older is ${toNlCurrency(
              limits.Limit2
            )} (based
            on ${hoursPerWeek} hrs/wk).`,
            show: true,
          }
        }
      } else {
        return {
          message: `The min. gross wage for Knowledge migrants 30 years or older is ${toNlCurrency(
            limits.Limit2
          )} (based
            on ${hoursPerWeek} hrs/wk).`,
          show: true,
        }
      }
    }
  } else if (f.eligible30percentRuling === 'Yes') {
    if (grossWagePerHour < limits.Limit4) {
      return {
        message: `This gross wage does not suffice for the 30% ruling. The min. gross wage for the 30% ruling is
          ${toNlCurrency(
            limits.Limit4
          )} for candidates under 30 with a Master OR ${toNlCurrency(
          limits.Limit3
        )} for all
          other candidates (based on ${hoursPerWeek} hrs/wk).`,
        show: false,
      }
    } else if (grossWagePerHour < limits.Limit5) {
      return {
        message: `Only candidates under 30 with a Master are eligible for the 30% ruling, for all others the min. gross wage
          is ${toNlCurrency(limits.Limit5)} (based on ${hoursPerWeek} hrs/wk).`,
        show: true,
      }
    }
  }
  return undefined
}
