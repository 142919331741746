import React, { useEffect, useState } from 'react'
import { AllInRateUnit, NonPayrollCommitmentForm } from '../types/types'
import { Input } from './Input'
import { Tooltips } from './strings'
import { TextNumberInput } from './TextNumberInput'
import { ALL_IN_RATE_UNIT_OPTIONS } from './values'

interface Props {
  form: NonPayrollCommitmentForm
  setForm: (form: NonPayrollCommitmentForm) => void
}

export const NonPayrollRateEntry = ({ form, setForm }: Props) => {
  const [maxSellRate, setMaxSellRate] = useState('')

  const onChangeMaxSellRate = (value: string, isLiberty: boolean) => {
    setMaxSellRate(value)
    const allInRate = isLiberty
      ? (Number(value) / 1.125 - 8.8).toFixed(2)
      : (Number(value) / 1.125).toFixed(2)
    setForm({ ...form, allInRate: String(allInRate) })
  }

  useEffect(() => {
    onChangeMaxSellRate(maxSellRate, isLibertyGlobalAndNoSOW)
  }, [form.client])

  // Exception.
  // If this gets any more complicated, split the LG logic out into its own separate component.
  const isLibertyGlobalAndNoSOW =
    form.type === 'SelfEmployed' &&
    form.statementOfWork === 'No' &&
    form.client === 'Liberty Global'

  const isTelenet = form.type === 'SelfEmployed' && form.client === 'Telenet'

  const isException = isLibertyGlobalAndNoSOW || isTelenet

  let tooltip
  if (isLibertyGlobalAndNoSOW) {
    tooltip = Tooltips.LGNoSOW
  } else if (isTelenet) {
    tooltip = Tooltips.Telenet
  }

  return (
    <div className={isException ? 'form-inline' : 'form-block'}>
      {isException && (
        <Input label="Max sell rate" toolTip={tooltip} toolTipWidth={550}>
          <TextNumberInput
            className="number-input-mr10"
            value={maxSellRate}
            decimals={2}
            prefix="&euro;"
            onChange={(value: string) =>
              onChangeMaxSellRate(value, isLibertyGlobalAndNoSOW)
            }
          />
          <span className="label">per day</span>
        </Input>
      )}
      <Input
        label={isException ? 'All-in Candidate rate' : 'All-in rate'}
        toolTip={
          isLibertyGlobalAndNoSOW
            ? Tooltips.LGNoSOW
            : isTelenet
            ? Tooltips.Telenet
            : undefined
        }
        toolTipWidth={550}
      >
        <TextNumberInput
          className="number-input-mr10"
          value={form.allInRate}
          readonly={isException}
          decimals={2}
          prefix="&euro;"
          onChange={(value: string) => setForm({ ...form, allInRate: value })}
        />
        {isException ? (
          <span className="label">per day</span>
        ) : (
          <select
            value={form.allInRateUnit}
            onChange={(e) =>
              setForm({
                ...form,
                allInRateUnit: e.target.value as AllInRateUnit,
              })
            }
          >
            {ALL_IN_RATE_UNIT_OPTIONS.map((opt) => (
              <option key={opt.value} value={opt.value}>
                {opt.name}
              </option>
            ))}
          </select>
        )}
      </Input>
    </div>
  )
}
