import React, { useEffect, useState } from 'react'
import { authedGet } from '../lib/http'
import { ProformaRequest } from '../types/types'

export const Leaderboard = () => {
  const [board, setBoard] = useState([] as any[])

  const loadLeaderboard = async () => {
    const result = await authedGet(`proformas/this-month`)
    const json = (await result.json()) as ProformaRequest[]
    const board = json.reduce((prev, cur) => {
      if (cur.CreatedByEmail) {
        if (prev[cur.CreatedByEmail]) {
          prev[cur.CreatedByEmail] += 1
        } else {
          prev[cur.CreatedByEmail] = 1
        }
      }
      return prev
    }, {} as Record<string, number>)
    const sortedBoard = Object.keys(board)
      .sort((keyA, keyB) => {
        const countA = board[keyA]
        const countB = board[keyB]
        if (countA < countB) return 1
        if (countA > countB) return -1
        return 0
      })
      .map((key) => ({
        name: key,
        score: board[key],
      }))
    setBoard(sortedBoard)
  }

  useEffect(() => {
    loadLeaderboard()
  }, [])

  return (
    <div>
      <div className="dashboard-form">
        <div className="popup-section-title">
          Biggest requesters for this month...
        </div>
        <table>
          <thead>
            <tr>
              <th></th>
              <th style={{ minWidth: 120 }}>Name</th>
              <th>Proformas requested</th>
            </tr>
          </thead>
          <tbody>
            {board.map((entry, idx) => (
              <tr key={entry.name}>
                <td>
                  {idx === 0 ? (
                    <span style={{ cursor: 'pointer' }} title="Topper!">
                      🏆
                    </span>
                  ) : (
                    ''
                  )}
                </td>
                <td>{entry.name}</td>
                <td>{entry.score}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}
