import React from 'react'
import { Link, useRoute } from 'wouter'

interface Props {
  href: string
  children: React.ReactNode
}

export const NavigationTab = (props: Props) => {
  const [isActive] = useRoute(props.href)

  return (
    <Link {...props}>
      <button className={isActive ? 'selected' : ''}>{props.children}</button>
    </Link>
  )
}
