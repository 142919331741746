import React from 'react'
import { ToolTip } from '../lib/ui/ToolTip'

interface Props {
  label: string
  toolTip?: string
  toolTipWidth?: number
  children: React.ReactChild | React.ReactChild[]
}

export const Input = ({ label, toolTip, toolTipWidth, children }: Props) => {
  return (
    <div>
      <div className={`label-inline ${toolTip ? 'label-with-tooltip' : ''}`}>
        {label}
        {toolTip && <ToolTip width={toolTipWidth} text={toolTip} />}
      </div>
      <div className="value">{children}</div>
    </div>
  )
}
