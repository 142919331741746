import React from 'react'
import { basename } from 'path'

import { getApiUrl, getSessionId } from '../lib/http'
import { CandidateProforma } from '../types/types'
import { toNlCurrency } from './mapper'

interface Props {
  candidateProforma: CandidateProforma
}

const getProformaDetails = (url: string) => {
  const name = basename(url, '.pdf')
  const details = name.split('_')
  return {
    name: name,
    grossWagePerHour: details[1],
    hoursPerWeek: details[2],
    workPermit: details[3],
    thirtyPercentRuling: details[4],
    url
  }
}

const getDownloadUrl = (url: string) => {
  const sessionId = getSessionId()
  return getApiUrl() + `/proformas/file?url=${url}&token=${sessionId}`
}

export const CandidateProformaResult = ({ candidateProforma }: Props) => {
  const urls = candidateProforma.DocumentUrls || ''

  return <div>
    <div className='form-inline'>
      <div>
        Gross wage per hour:
      </div>
      <div>
        {toNlCurrency(candidateProforma.GrossWagePerHour || 0)}
      </div>
    </div>
    <div className='form-inline'>
      <div>
        Cost price per hour:
      </div>
      <div>
        {toNlCurrency(candidateProforma.CostPricePerHour || 0)}
      </div>
    </div>

    <div><h3>Proformas</h3></div>

    {urls.split(',').map((url, idx) => {
      const details = getProformaDetails(url)

      return <div key={idx} className='form-inline'>
        <div>{details.name}</div>
        <div><a target='_new'
            href={getDownloadUrl(url)}>
            Download
          </a></div>
      </div>
    })}
  </div>
}
