import React from 'react'
import { NonPayrollCommitmentForm, PayrollForm, YesNo } from '../types/types'
import { Input } from './Input'
import { Tooltips } from './strings'
import { YES_NO_OPTIONS } from './values'

interface Props {
  isDisabled?: boolean
  form: NonPayrollCommitmentForm | PayrollForm
  setForm: (form: NonPayrollCommitmentForm | PayrollForm) => void
}

export const MigrantEntry = ({ form, setForm, isDisabled }: Props) => {
  // Exception for Telenet
  const isTelenet = form.type === 'Permanent' && form.client === 'Telenet'
  console.log(form)

  return (
    <>
      <div className="form-block">
        <Input
          label="Requires work permit"
          toolTip={Tooltips.WorkPermit}
          toolTipWidth={500}
        >
          <select
            disabled={isDisabled}
            value={form.requiresWorkPermit}
            onChange={(e) =>
              setForm({
                ...form,
                requiresWorkPermit: e.target.value as YesNo,
              })
            }
          >
            {YES_NO_OPTIONS.map((opt) => (
              <option key={opt} value={opt}>
                {opt}
              </option>
            ))}
          </select>
        </Input>
      </div>
      {!isTelenet && (
        <div className="form-block">
          <Input
            label="Eligible 30% ruling"
            toolTip={Tooltips.ThirtyPercentRuling}
            toolTipWidth={550}
          >
            <select
              disabled={isDisabled}
              value={form.eligible30percentRuling}
              onChange={(e) =>
                setForm({
                  ...form,
                  eligible30percentRuling: e.target.value as YesNo,
                })
              }
            >
              {YES_NO_OPTIONS.map((opt) => (
                <option key={opt} value={opt}>
                  {opt}
                </option>
              ))}
            </select>
          </Input>
        </div>
      )}
    </>
  )
}
