import { useEffect, useState } from 'react'
import { authedGet } from '../lib/http'
import { Client } from '../types/types'

export function useClients() {
  const [clients, setClients] = useState([] as Client[])

  const fetchClients = async () => {
    const result = await authedGet(`client`)
    const json = await result.json() as Client[]
    setClients(json)
  }

  useEffect(() => {
    fetchClients()
  }, [])

  return {
    clients: clients
  }
}
